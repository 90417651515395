import Vue from 'vue'
window.Vue = require('vue')

import './fakeLocalStorage'
import settings from './settings.json'
import router from './router.js'
import i18n from './i18n'

import App from './App.vue'

import './registerServiceWorker'
import './lib/logBuildInfo'

import { BootstrapVue, IconsPlugin, BVToastPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BVToastPlugin)
Vue.use(IconsPlugin)

import VueEvents from 'vue-events'
Vue.use(VueEvents)

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowLeft,
  faHotel,
  faPen,
  faAngleRight,
  faSearch,
  faBars,
} from '@fortawesome/free-solid-svg-icons'
library.add(faArrowLeft)
library.add(faAngleRight)
library.add(faPen)
library.add(faHotel)
library.add(faSearch)
library.add(faBars)
Vue.component('fa', FontAwesomeIcon)

import {
  MdField,
  MdMenu,
  MdList,
  MdDatepicker,
  MdButton,
  MdDialog,
  MdCheckbox,
  MdDialogAlert,
  MdDialogConfirm,
  MdProgress,
} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(MdField)
Vue.use(MdMenu)
Vue.use(MdList)
Vue.use(MdDatepicker)
Vue.use(MdButton)
Vue.use(MdDialog)
Vue.use(MdCheckbox)
Vue.use(MdDialogAlert)
Vue.use(MdDialogConfirm)
Vue.use(MdProgress)

import '@/scss/elementui-variables.scss'
import {
  Icon,
  Form,
  FormItem,
  Button,
  DatePicker,
  Checkbox,
  Link,
  Loading,
  Select,
  Option,
  Steps,
  Step,
  Switch,
} from 'element-ui'
Vue.use(Icon)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(Link)
Vue.use(Loading)
Vue.use(Select)
Vue.use(Option)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Switch)

//el-input have problems with mobile: no instant reactivity, bugs with mask
//el-input-plain copies it style but fixes them
import ElInputPlain from '@/components/ElInputPlain'
Vue.component('el-input-plain', ElInputPlain)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/analytics'
firebase.initializeApp(settings.firebase)
Vue.firebase = firebase

import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

import VueRellax from 'vue-rellax'
Vue.use(VueRellax)

import VueWorker from 'vue-worker'
Vue.use(VueWorker)

import WindowEvents from '@/mixins/WindowEvents'
Vue.mixin(WindowEvents)

import SessionStorage from '@/mixins/SessionStorage.vue'
Vue.mixin(SessionStorage)

import FullscreenLoading from '@/components/FullscreenLoading'
Vue.component('Loading', FullscreenLoading)

import stripHtml from 'string-strip-html'
import axios from 'axios'
import store from './store'
import { v4 } from 'uuid'
Vue.use({
  install(Vue) {
    Vue.prototype.$store = new Vue(store)
    Vue.prototype.$stripHtml = html => {
      if (!html) return html
      return stripHtml(html).trim()
    }

    if (!localStorage.deviceId) localStorage.deviceId = v4()

    const API_HEADERS = {
      'UserToken-Agent': 'WEB',
      'x-api-key': process.env.VUE_APP_API_KEY,
      'x-tz-offset': new Date().getTimezoneOffset(),
      'x-device-id': localStorage.deviceId,
    }

    Vue.prototype.$api = axios.create({
      baseURL: process.env.VUE_APP_API_URL + '/',
      headers: API_HEADERS,
    })
    Vue.prototype.$defaultApi = axios.create({
      baseURL: process.env.VUE_APP_API_URL + '/',
      headers: API_HEADERS,
    })
    Vue.prototype.$externalApi = axios.create({
      headers: API_HEADERS,
    })
  },
})

Vue.use({
  install(Vue) {
    Vue.prototype.$waitFor = condition => {
      return new Promise(resolve => {
        const int = setInterval(() => {
          if (!condition()) return
          clearInterval(int)
          resolve(condition())
        }, 10)
      })
    }
  },
})

Vue.mixin({
  computed: {
    $full() {
      return this.$store.isFull
    },
  },
})

// директива v-html-reactive-links рассчитана на использование с v-html
// компонентами, где html приходит с сервера. обычная <a> ломала бы spa
// так что это призвано это чинить
Vue.use({
  install(Vue) {
    function makeLinksReactive(el, router) {
      const links = [...el.querySelectorAll('a')]
      for (const link of links) {
        if (!link.href.startsWith(location.origin)) continue
        if (link.reactive) continue
        if (link.__vue__?.to) continue
        link.reactive = true
        link.onclick = () => {
          const path = new URL(link.href).pathname
          router.push(path)
          return false
        }
      }
    }
    Vue.directive('html-reactive-links', {
      // eslint-disable-next-line
      bind(_el, _binding, vnode, _oldVnode) {
        makeLinksReactive(vnode.elm, vnode.context.$router)
      },
      // eslint-disable-next-line
      update(_el, _binding, vnode, _oldVnode) {
        makeLinksReactive(vnode.elm, vnode.context.$router)
      },
    })
  },
})

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

/**
 * На сервере и клиенте разный часовой пояс. Служит для его синхронизации
 *
 * @param {boolean} side если с сервера на клиент - true
 * @returns Date
 */

Date.prototype.normalize = function(side) {
  let currentTimeZoneOffset = this.getTimezoneOffset() * 60000

  let sideDate = new Date(this.getTime() + currentTimeZoneOffset)

  return !side ? this.getTime() - currentTimeZoneOffset : sideDate
}

window.onload = () => {
  if (
    navigator.userAgent.includes('diordnA') &&
    navigator.userAgent.includes('Focus') &&
    navigator.maxTouchPoints == 0
  ) {
    window.location.replace('https://tv.cncrg.org/app-release.apk')
  }
}

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
